.ourServices {
    background: url('../../../public/images/BG.svg');
    background-size: cover;
    background-repeat: no-repeat;
    height: fit-content;
    width: 100%;
    position: relative;
}

.ourServices__container {
    width: 45%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
}

.ourServices__container>h1 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 140%;
    /* identical to box height, or 70px */

    text-align: center;

    color: #20223C;
}

.ourServices__container>h4 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;

    color: #FC3073;
}

.ourServices__container__box {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.ourServices__container__box__item {
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 30px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(54, 53, 101, 0.25);
    padding: 40px;
    width: 100%;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
}

.ourServices__container__box__item img {
    width: 196px;
    height: 196px;
}

.ourServices__container__box__item div h2 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 34px;
    line-height: 46px;
    text-transform: capitalize;
    text-align: left;
    color: #8779F2;
}

.ourServices__container__box__item div p {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #20223C;
    text-align: left;
    margin-top: 25px;
}

.ourServices__container__box__item div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
}

.ourServices__container__box__item .item-grid {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
    margin-top: 1rem;
}

.ourServices__container__box__item .item-grid img {
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
}

.ourServices__container__box__item .item-grid span {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.ourServices__container__box__item .item-grid span p {
    margin-top: 0;
}

.ourServices__container__form {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(54, 53, 101, 0.25);
    border-radius: 10px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ourServices__container__form__inputs{
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1rem;
    margin-top: 40px;
}

.ourServices__container__form__inputs input{
    height: 3rem;
    border: none;
    border-bottom: 1px solid #000000;
}

.ourServices__container__form button{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
}

.ourServices__container__form p{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    color: #20223C;
}

.ourServices__container__form__inputs > input{
    margin-left: 2rem;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #8C8E9D;
}

#line4{
    position: absolute;
    left: 48.96%;
    right: 2.97%;
    top: -0.59%;
    bottom: 60.31%;
    z-index: 0;
    width: 50%;
}

.ourServices__container__box__item .item-grid{
    width: 100%;
}

.ourServices__container__box__item .item-grid span p{
    width: 100%;
}

#line5{
    position: absolute;
    left: 0%;
    right: 59.28%;
    top: 36.45%;
    bottom: 8.22%;
}

@media screen and (max-width: 1800px){
    .ourServices__container{
        width: 55%;
    }
    .ourServices__container__box__item .item-grid span{
        width: 80%;
    }
}

@media screen and (max-width: 1400px){
    .ourServices__container{
        width: 65%;
    }
    .ourServices__container__form{
      z-index: 1;       
    }
}

@media screen and (max-width: 1220px){
    .ourServices{
        background: url('../../../public/images/BG-tablet.png');
        background-size: cover;
        background-repeat: no-repeat;
    }
    #line4, #line5{
        display: none;
    }
    .ourServices__container{
        width: 80%;
    }
}

@media screen and (max-width: 900px){
    .ourServices__container{
        padding: 18px;
        width: 100%;
    }
    .ourServices__container__box__item .item-grid{
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 700px){
    .ourServices{
        background: url('../../../public/images/BG-mobile.png');
        background-size: cover;
        background-repeat: no-repeat;
    }
    .ourServices__container__box__item{
        grid-template-columns: 1fr;
    }
    .ourServices__container__box__item img{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 590px){
    .ourServices__container__box__item{
        width: 490px;
    }
    .ourServices__container__form{
        width: 490px;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
    }
    .ourServices__container__box{
        justify-content: center;
    }
    .ourServices__container__box__item div p{
        text-align: center;
    }
    .ourServices__container__box__item .item-grid span p{
        text-align: left;
    }
    .ourServices__container__box__item div h2{
        text-align: center;
    }
    .ourServices__container__form__inputs{
        grid-template-columns: 1fr;
    }
    .ourServices__container__form__inputs > input{
        margin-left: 0;
    }
    .PhoneInput{
        width: 100%;
    }
    .ourServices__container>h1{
        font-size: 25px;
    }
    .ourServices__container>h4{
        font-size: 18px;
    }
    .ourServices__container p{
        font-size: 14px;
    }
    .ourServices__container{
        margin-top: 30px;
    }
    .ourServices__container__box__item img{
        width: 135px;
        height: 135px;
    }
    .ourServices__container__form button{
        width: 173px;
        height: 47px;
        font-size: 18px;
        margin-left: 0;
    }
}

@media screen and (max-width: 520px){
    .ourServices__container__box__item div p{
        font-size: 14px;
    }
}

@media screen and (max-width: 503px){
    .ourServices__container__box__item{
        width: 420px;
    }
    .ourServices__container__form{
        width: 420px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 443px){
    .ourServices__container__box__item{
        width: 390px;
    }
    .ourServices__container__form{
        width: 390px;
    }
}

@media screen and (max-width: 403px){
    .ourServices__container__box__item{
        width: 370px;
    }
    .ourServices__container__form{
        width: 370px;
    }
}

@media screen and (max-width: 378px){
    .ourServices__container__box__item{
        width: 350px;
    }
    .ourServices__container__form{
        width: 350px;
    }
}