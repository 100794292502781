button{
    width: 197px;
    height: 62px;
    border-radius: 57px;
    margin-top: 36px;
    margin-right: 37%;
    cursor: pointer;
    border: none;
    background: #20223C;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: white;
    transition: 400ms;
}

button:hover{
    background: #8779F2;
}