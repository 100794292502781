.header .header__hamburger{
    display: none;
}

.header .header__navbar__mobile{
    display: none;
}

.header{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: transparent;
    padding-top: 60px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    position: relative;
}

.header .header__navbar #nav-item{
    color: #20223C;
    text-decoration: none;
    margin-right: 42px;
    transition: 400ms;
    font-size: 20px;
    font-weight: 600;
}

.header .header__navbar #nav-item:hover{
    color: #FC3073;
}

.header .header__contact{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.header .header__contact span{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 42px;
}

.header .header__contact span img{
    margin-right: 1rem;
    width: 30px;
    height: 30px;
}

.header .header__contact span p{
    font-size: 18px;
    font-weight: 600;
}

.header__logo img{
    width: 170px;
    height: 48px;
}

@media screen and (max-width: 1620px){
    .header{
        width: 90%;
    }
}

@media screen and (max-width: 1140px){
    .header{
        justify-content: space-between;
        padding-left: 19px;
        padding-right: 19px;
        position: relative;
        padding-top: 40px;
        width: 100%;
    }
    .header .header__navbar{
        display: none;
    }
    .header .header__contact{
        display: none;
    }
    .header .header__hamburger{
        display: flex;
    }
    .header .header__navbar__mobile{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background: #20223C;
        width: 100%;
        height: 540px;
        position: absolute;
        top: 0;
        left: 0;
        animation: animateNav 0.5s linear;
    }
    @keyframes animateNav{
        0%{
            top: -800%;
        }
        100%{
            top: 0%;
        }
    }
    .header .header__navbar__mobile #nav-item{
        color: white;
        text-decoration: none;
        margin-right: 2rem;
        transition: 400ms;
        font-size: 16px;
        font-weight: 600;
        margin-top: 20px;
    }

    .header .header__navbar__mobile .header__navbar__mobile__head{
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-left: 20px;
        padding-right: 23px;
        margin-bottom: 40px;
    }
    .header .header__navbar__mobile .header__navbar__mobile__head #close{
        width: 20px;
        height: 20px;
    }
    .header .header__navbar__mobile span{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: white;
        margin-top: 22px;
        margin-right: 2rem;
    }
    .header .header__navbar__mobile span img{
        width: 24px;
        height: 24px;
        margin-right: 0.5rem;
    }
    .header .header__navbar__mobile span p{
        font-size: 16px;
        font-weight: 600;
    }
    .header__navbar__mobile__socials{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 150px;
        width: 40%;
    }

    .header__navbar__mobile__socials img{
        width: 30px;
        height: 30px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}