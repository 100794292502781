.ourStory{
    background: url('../../../public/images/BG.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
}

.ourStory__container{
    width: 65%;
    display: grid;
    grid-template-columns: 50% 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 81px;
    height: fit-content;
}

.ourStory__container__left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    text-align: left;
    margin-left: 3rem;
}

.ourStory__container__left h1{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 140%;
    color: #20223C;
}

.ourStory__container__left p {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #20223C;
    margin-top: 34px;
}

.ourStory__container__right img{
    margin-top: -12rem;
    width: 150%;
}

.ourStory .footer{
    margin-top: 6rem;
}

@media screen and (max-width: 1400px){

    .ourStory{
        background: url('../../../public/images/BG-tablet.png');
        background-size: cover;
        background-repeat: no-repeat;
    }
    .ourStory__container{
        grid-template-columns: 1fr;
    }
    .ourStory__container__left{
        order: 2;
        width: 100%;
        margin-left: 0;
    }
    .ourStory__container__right{
        order: 1;
    }
    .ourStory__container__right img{
        width: 100%;
    }
}

@media screen and (max-width: 780px){
    .ourStory{
        background: url('../../../public/images/BG-mobile.png');
        background-size: cover;
        background-repeat: no-repeat;
    }
    .ourStory__container{
        margin-top: 100px;
    }
}

@media screen and (max-width: 520px){
    .ourStory__container{
        margin-top: 200px;
    }
}