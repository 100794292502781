#line-mobile {
    display: none;
}

.homePage {
    background: url('../../../public/images/BG.svg');
    background-size: cover;
    background-repeat: no-repeat;
    height: fit-content;
    width: 100%;
    position: relative;
}

.homePage__hero {
    width: 60%;
    display: grid;
    grid-template-columns: 40% 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 93px;
}

.homePage__hero__left {
    text-align: left;
}

.homePage__hero__left h1 {
    font-size: 50px;
    color: #20223C;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    text-align: left;
}

.homePage__hero__left p {
    font-size: 16px;
}

.homePage__hero__left #arrow-list-item {
    display: flex;
    justify-content: baseline;
    align-items: center;
    margin-top: 1rem;
}

.homePage__hero__left #arrow-list-item img {
    margin-right: 29px;
}

.homePage__hero__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.homePage__hero__right__form {
    padding: 45px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 4px rgba(54, 53, 101, 0.25);
    border-radius: 10px;
    width: 400px;
    z-index: 1;
    margin-left: 7rem;
}

.homePage__hero__right__form input {
    width: 90%;
    height: 3rem;
    border: none;
    border-bottom: 1px solid #000000;
    margin-top: 1rem;
    font-size: 16px;
}

.homePage__hero__right__form button {
    width: 197px;
    height: 62px;
    border-radius: 57px;
    margin-top: 36px;
    margin-right: 37%;
    cursor: pointer;
    border: none;
    background: #20223C;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: white;
    transition: 400ms;
}

.homePage__hero__right__form button:hover {
    background: #8779F2;
}

#line {
    position: absolute;
    top: -3.5%;
    right: 66px;
    z-index: 0;
    right: 0.7%;
}

.PhoneInput {
    width: 90%;
}

.PhoneInputCountry {
    border-bottom: 1px solid black;
}

.homePage__sectionTwo {
    margin-top: 100px;
    width: fit-content;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.homePage__sectionTwo>h1 {
    width: 60%;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 140%;
    text-align: center;
    color: #20223C;
    z-index: 1;
}

.homePage__sectionTwo__box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 34px;
    margin-top: 60px;
    width: fit-content;
}

#item1 {
    grid-area: 1 / 1 / 2 / 2;
}

#item2 {
    grid-area: 1 / 2 / 2 / 3;
}

#item3 {
    grid-area: 1 / 3 / 2 / 4;
}

#item4 {
    grid-area: 2 / 1 / 3 / 2;
}

#item5 {
    grid-area: 2 / 2 / 3 / 3;
}

.homePage__sectionTwo__box__item:nth-child(n+5):nth-child(-n+5) {
    grid-column: 2 / span 2 !important;
    margin: 0 auto;
}

.homePage__sectionTwo__box__item:nth-child(n+4):nth-child(-n+4) {
    grid-column: 1 / span 2 !important;
    margin: 0 auto;
}

.homePage__sectionTwo__box__item {
    width: 420px;
    height: 183px;
    display: grid;
    grid-template-columns: 20% 80%;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(54, 53, 101, 0.25);
    z-index: 1;
}

.animate {
    animation: bounce .5s infinite;
    animation-iteration-count: 1;
}

@keyframes bounce {

    from,
    to {
        transform: scale(1, 1);
    }

    25% {
        transform: scale(0.9, 1.1);
    }

    50% {
        transform: scale(1.1, 0.9);
    }

    75% {
        transform: scale(0.95, 1.05);
    }
}

.homePage__sectionTwo__box__item p {
    font-size: 16px;
    color: #20223C;
    font-family: 'Mulish';
}

.homePage__sectionTwo__box__item h3 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    text-transform: capitalize;
    color: #20223C;
}

.homePage__sectionTwo__box__item h1 {
    padding: 20px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 100px;
    line-height: 150%;
}

.homePage__sectionTwo__box__item div {
    padding: 20px;
    text-align: left;
}

.homePage__sectionTwo__box__item img {
    margin-left: 1.5rem;
}

#line2 {
    position: absolute;
    left: -2%;
    right: 0%;
    top: 10%;
    bottom: 18.05%;
    z-index: 0;
}

.homePage__sectionThree {
    width: 60%;
    display: grid;
    grid-template-columns: 50% 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 72px;
    gap: 100px;
    position: relative;
}

.homePage__sectionThree__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
}

.homePage__sectionThree__left p {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #20223C;
    margin-top: 34px;
}

.homePage__sectionThree__left h1 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 140%;
    /* identical to box height, or 70px */

    text-align: center;

    color: #20223C;
}

.homePage__sectionThree__right {
    z-index: 1;
}

#line3 {
    position: absolute;
    right: -33%;
    top: -20%;
    bottom: -1.81%;
    z-index: 0;
}

@media screen and (max-width: 1789px) {
    .homePage__sectionTwo__box__item {
        z-index: 2;
    }

    #line {
        width: 50%;
        top: 0%;
    }
}

@media screen and (max-width: 1600px) {
    #line {
        right: 80px;
    }
}

@media screen and (max-width: 1500px) {
    .homePage__hero {
        width: 70%;
    }
}

@media screen and (max-width: 1492px) {
    #line {
        right: 50px;
    }

    .homePage__sectionThree__left button {
        margin-left: 3.5rem;
    }
}

@media screen and (max-width: 1463px) {
    .homePage__sectionThree {
        gap: 0;
    }

    .homePage__sectionThree__left button {
        margin-left: 4rem;
    }
}

@media screen and (max-width: 1356px) {
    #line {
        right: 30px;
    }
}

@media screen and (max-width: 1300px) {

    .homePage__sectionTwo>h1{
        width: 80%;
    }

    .homePage__sectionTwo__box{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 34px;
    }

    #item1 { grid-area: 1 / 1 / 2 / 2; }
    #item2 { grid-area: 1 / 2 / 2 / 3; }
    #item3 { grid-area: 2 / 1 / 3 / 2; }
    #item4 { grid-area: 2 / 2 / 3 / 3; }
    #item5 { grid-area: 3 / 1 / 4 / 2;}

    .homePage__sectionTwo__box__item:nth-child(n+5):nth-child(-n+5) {
        grid-column: 1 / span 2 !important;
        margin: 0 auto;
    }

    .homePage__sectionTwo__box__item:nth-child(n+4):nth-child(-n+4) {
        grid-column: 2 / span 1 !important;
        margin: 0 auto;
    }

    .homePage__sectionThree {
        width: 80%;
    }

    #line3 {
        right: -12.5%;
    }

    .homePage__sectionThree__right img {
        width: 123%;
    }

    .homePage__sectionTwo__box__item {
        width: 360px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 1269px) {
    #line {
        width: 55%;
        right: 0;
    }

    .homePage__hero__left h1 {
        font-size: 40px;
    }
}

@media screen and (max-width: 1178px) {
    #line {
        display: none;
    }

    #line {
        display: none;
    }

    #line-mobile {
        display: block;
        z-index: 0;
        position: absolute;
        right: 0;
        top: 0%;
        width: 55%;
    }

    .homePage__hero {
        width: 80%;
    }

    .homePage__hero__right__form {
        margin-left: 3rem;
    }

    .homePage__sectionThree__left {
        z-index: 1;
        position: relative;
    }

    #line3 {
        width: 50%;
    }
}

@media screen and (max-width: 1010px) {
    .homePage__sectionThree__left button {
        margin-left: 5rem;
    }
}

@media screen and (max-width: 900px) {

    .homePage{
        background: url('../../../public/images/BG-tablet.png');
        background-size: cover;
        background-repeat: no-repeat;
    }

    .homePage__sectionTwo>h1{
        width: 100%;
        z-index: 1;
        font-size: 40px;
    }

    .homePage__hero {
        grid-template-columns: auto;
        width: 100%;
        margin-top: 40px;
    }

    .homePage__hero__left {
        padding-left: 29px;
    }

    .homePage__hero__right__form {
        margin-left: 0;
        margin-top: 40px;
    }

    #line {
        display: none;
    }

    #line-mobile {
        display: block;
        z-index: 0;
        position: absolute;
        right: 0;
        top: 5.3%;
        width: 100%;
    }

    .homePage__hero__left h1 {
        font-size: 50px;
    }

    .homePage__hero__left {
        z-index: 1;
    }

    .homePage__sectionThree__left button {
        margin-left: 6.5rem;
    }

    .homePage__hero__right__form {
        width: 460px;
        height: 440px;
    }

    .homePage__sectionThree {
        width: 90%;
    }

    #line3 {
        right: -5.5%;
    }

    .homePage__sectionThree__right img {
        width: 120%;
    }

    .homePage__sectionThree__right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #line2{
        z-index: 0;
    }
}

@media screen and (max-width: 791px){
    .homePage__sectionTwo{
        width: 80%;
    }
    .homePage__sectionTwo__box__item{
        width: 340px
    }
    .homePage__sectionTwo__box__item p{
        font-size: 14px;
    }
    #line2{
        left: -12%;
    }
}

@media screen and (max-width: 714px){
    .homePage__sectionTwo__box{
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 20px;
    }
    #item1 { grid-area: 1 / 1 / 2 / 2; }
    #item2 { grid-area: 2 / 1 / 3 / 2; }
    #item3 { grid-area: 3 / 1 / 4 / 2; }
    #item4 { grid-area: 4 / 1 / 5 / 2; }
    #item5 { grid-area: 5 / 1 / 6 / 2; }

    #line-mobile, #line2, #line3{
        display: none;
    }

    .homePage__sectionTwo__box__item:nth-child(n+4):nth-child(-n+4){
        grid-column: 1 / span 1 !important;
        margin: 0 auto;
    }

    .homePage__sectionTwo>h1{
        font-size: 25px;
    }
    .homePage__hero__left h1{
        font-size: 25px;
    }
    .homePage__sectionThree{
        grid-template-columns: 1fr;
    }
    .homePage__sectionThree__left{
        order: 2;
    }
    .homePage__sectionThree__right{
        order: 1;
    }
    .homePage__sectionThree__left button{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .homePage__hero__left{
        padding-right: 20px;
    }
    .homePage__sectionThree__right img{
        width: 100%;
    }
}

@media screen and (max-width: 471px){
    .homePage{
        background: url('../../../public/images/BG-mobile.png');
        background-size: cover;
        background-repeat: no-repeat;
    }
    .homePage__hero__right__form{
        width: 98%;
    }
    .homePage__sectionThree__right img{
        width: 322px;
        height: 322px;
    }
    .homePage__sectionTwo__box__item{
        width: 400px;
    }
}

@media screen and (max-width: 409px){
    .homePage__sectionTwo__box__item{
        width: 350px;
    }
    .homePage__hero__left p{
        font-size: 14px;
    }
    .homePage__sectionThree__left p{
        font-size: 14px;
    }

    .homePage__sectionThree__left button{
        font-size: 18px;
    }
}

@media screen and (max-width: 360px){
    .homePage__sectionThree__left button{
        width: 326px;
    }
}