.footer{
    height: fit-content;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    background: #20223C;
    margin-top: 5rem;
    padding: 40px;
    z-index: 1;
    position: relative;
}

.footer__logo{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.footer__logo img{
    width: 170px;
    height: 48px;
}

.footer__logo p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
}

.footer__links{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: baseline;
    margin-left: 10rem;
}

.footer__links #nav-item{
    color: white;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-decoration: none;
}

.footer__contact{
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer__contact span{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.footer__contact span img{
    margin-right: 22px;
}

.footer__socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
}

#mobile-copyright{
    display: none;
}

#mobile-socials{
    display: none;
}

@media screen and (max-width: 1251px){
    .footer__links{
        margin-left: 5rem;
    }
}

@media screen and (max-width: 900px){
    .footer{
        display: flex;
        flex-direction: column;
    }
    .footer__links{
        margin-left: 0;
        align-items: center;
        margin-top: 49px;
    }
    .footer__links #nav-item{
        margin-top: 49px;
    }
    .footer__socials{
        margin-top: 30px;
        justify-content: center;
        display: none;
    }
    .footer__socials img{
        margin-right: 1rem;
    }

    .footer__logo p{
        display: none;
    }

    .footer__contact span{
        margin-top: 49px;
        font-size: 18px;
    }

    #mobile-copyright{
        display: flex;
        color: white;
        margin-left: auto;
        margin-right: auto;
        margin-top: 49px;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        color: #FFFFFF;
    }

    #mobile-socials{
        display: flex;
        width: fit-content;
    }

    #mobile-socials img{
        width: 30px;
        height: 30px;
        margin-right: 47px;
    }

    .footer__logo{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

@media screen and (max-width: 500px){
    #mobile-socials{
        display: none;
    }
    .footer__socials{
        display: flex;
        margin-top: 49px;
    }
    .footer__socials img{
        margin-left: 40px;
    }
    .footer__logo{
        flex-direction: column;
    }
    .footer__links{
        margin-top: 0;
    }
}